<template>
  <div>
    <base-page-heading title="Adaugare utilizator" subtitle="Adaugare si setare utilizator nou">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item href="javascript:void(0)">Utilizatori</b-breadcrumb-item>
          <b-breadcrumb-item active>Utilizator nou</b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <!-- END Hero -->

    <div class="content">
      <b-row class="row-deck">
        <b-col xl="12">
          <base-block>
            <b-form @submit.stop.prevent="onAddUserSubmit">
              <b-row>
                <b-col xl="6">
                  <base-block title="Introduce date personale" header-bg content-full>
                    <b-row>
                      <b-col xl="8" offset-lg="2">
                        <base-block header-bg>
                          <div class="font-size-sm push">
                              <div class="form-group">
                                <div class="form-group">
                                  <b-form-input size="lg" class="form-control-alt" id="firstName" name="firstName" placeholder="Prenume" v-model="$v.addUserForm.firstName.$model" :state="!$v.addUserForm.firstName.$error && (!errors.addUserForm.firstName) && null" aria-describedby="firstName-feedback" @blur="removeServerErrors('addUserForm')" @input="removeServerErrors('addUserForm')"></b-form-input>
                                  <b-form-invalid-feedback v-if="!$v.addUserForm.firstName.required" id="firstName-feedback">
                                    Campul este obligatoriu!
                                  </b-form-invalid-feedback>
                                  <b-form-invalid-feedback v-if="!$v.addUserForm.firstName.minLength" id="firstName-feedback">
                                    Campul trebuie sa aiba minimum 3 caractere!
                                  </b-form-invalid-feedback>
                                  <b-form-invalid-feedback v-if="errors.addUserForm.firstName" v-text="errors.addUserForm.firstName" id="firstName-feedback"></b-form-invalid-feedback>
                                </div>
                                <div class="form-group">
                                  <b-form-input size="lg" class="form-control-alt" id="lastName" name="lastName" placeholder="Nume" v-model="$v.addUserForm.lastName.$model" :state="!$v.addUserForm.lastName.$error && (!errors.addUserForm.lastName) && null" aria-describedby="lastName-feedback" @blur="removeServerErrors('addUserForm')" @input="removeServerErrors('addUserForm')"></b-form-input>
                                  <b-form-invalid-feedback v-if="!$v.addUserForm.lastName.required" id="firstName-feedback">
                                    Campul este obligatoriu!
                                  </b-form-invalid-feedback>
                                  <b-form-invalid-feedback v-if="!$v.addUserForm.lastName.minLength" id="firstName-feedback">
                                    Campul trebuie sa aiba minimum 3 caractere!
                                  </b-form-invalid-feedback>
                                  <b-form-invalid-feedback v-if="errors.addUserForm.lastName" v-text="errors.addUserForm.lastName" id="firstName-feedback"></b-form-invalid-feedback>
                                </div>
                                <div class="form-group">
                                  <b-form-input size="lg" class="form-control-alt" id="email" name="email" placeholder="E-mail" v-model="$v.addUserForm.email.$model" :state="!$v.addUserForm.email.$error && (!errors.addUserForm.email) && null" aria-describedby="email-feedback" @blur="removeServerErrors('addUserForm')" @input="removeServerErrors('addUserForm')"></b-form-input>
                                  <b-form-invalid-feedback v-if="!$v.addUserForm.email.required" id="firstName-feedback">
                                    Campul este obligatoriu!
                                  </b-form-invalid-feedback>
                                  <b-form-invalid-feedback v-if="!$v.addUserForm.email.email" id="email-feedback">
                                    Adresa de email este invalida!
                                  </b-form-invalid-feedback>
                                  <b-form-invalid-feedback v-if="errors.addUserForm.email" v-text="errors.addUserForm.email" id="firstName-feedback"></b-form-invalid-feedback>
                                </div>
                                <b-form-group label-for="headquarter">
                                  <b-form-select id="headquarter" size="lg" class="form-control-alt" :options="headquartersChoices" plain name="headquarter" v-model="$v.addUserForm.headquarter.$model" :state="!$v.addUserForm.headquarter.$error && (!errors.addUserForm.headquarter) && null" aria-describedby="headquarter-feedback" @blur="removeServerErrors('addUserForm')" @input="removeServerErrors('addUserForm')"></b-form-select>
                                  <b-form-invalid-feedback v-if="!$v.addUserForm.headquarter.required" id="headquarter-feedback">
                                    Campul este obligatoriu!
                                  </b-form-invalid-feedback>
                                  <b-form-invalid-feedback v-if="errors.addUserForm.headquarter" v-text="errors.addUserForm.headquarter" id="headquarter-feedback"></b-form-invalid-feedback>
                                </b-form-group>
                              </div>
                          </div>
                        </base-block>
                      </b-col>
                    </b-row>
                  </base-block>
                </b-col>
                <b-col xl="6">
                  <base-block title="Setare parola si acces la module" header-bg content-full>
                    <b-row>
                      <b-col xl="8" offset-lg="2">
                        <base-block header-bg>
                          <div class="font-size-sm push">
                            <div class="form-group">
                              <b-form-input autocomplete="new-password" type="password" size="lg" class="form-control-alt" id="plainPassword" name="plainPassword" placeholder="Parola" v-model="$v.addUserForm.plainPassword.$model" :state="!$v.addUserForm.plainPassword.$error && (!errors.addUserForm.plainPassword) && null" aria-describedby="newPassword-feedback" @blur="removeServerErrors('addUserForm')" @input="removeServerErrors('addUserForm')"></b-form-input>
                              <b-form-invalid-feedback v-if="!$v.addUserForm.plainPassword.required" id="newPassword-feedback">
                                Campul este obligatoriu!
                              </b-form-invalid-feedback>
                              <b-form-invalid-feedback v-if="!$v.addUserForm.plainPassword.minLength" id="newPassword-feedback">
                                Campul trebuie sa aiba minimum 6 caractere!
                              </b-form-invalid-feedback>
                              <b-form-invalid-feedback v-if="errors.addUserForm.plainPassword" v-text="errors.addUserForm.plainPassword" id="newPassword-feedback"></b-form-invalid-feedback>
                            </div>
                            <div class="form-group">
                              <b-form-input type="password" size="lg" class="form-control-alt" id="passwordConfirm" name="passwordConfirm" placeholder="Repeta parola" v-model="$v.addUserForm.passwordConfirm.$model" :state="!$v.addUserForm.passwordConfirm.$error && null" aria-describedby="passwordConfirm-feedback" @blur="removeServerErrors('addUserForm')" @input="removeServerErrors('addUserForm')"></b-form-input>
                              <b-form-invalid-feedback v-if="!$v.addUserForm.passwordConfirm.sameAsPassword" id="passwordConfirm-feedback">
                                Parola nu se potriveste!
                              </b-form-invalid-feedback>
                            </div>
                          </div>
                        </base-block>
                        <b-form-group label="Permite acces la urmatoarele module" label-class="font-md">
                          <b-form-checkbox-group :options="enabledModulesChoices" stacked name="enabledModules" v-model="$v.addUserForm.enabledModules.$model" :state="!$v.addUserForm.enabledModules.$error && (!errors.addUserForm.enabledModules) && null" aria-describedby="enabledModules-feedback" @blur="removeServerErrors('addUserForm')" @input="removeServerErrors('addUserForm')"></b-form-checkbox-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </base-block>
                </b-col>
              </b-row>
              <b-row>
                <b-col offset-md="5" md="2">
                  <base-block header-bg>
                  <b-button type="submit" variant="primary" block>
                    <i class="fa fa-fw fa-plus-circle mr-1"></i> Adauga
                  </b-button>
                  </base-block>
                </b-col>
              </b-row>
            </b-form>
          </base-block>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {email, minLength, required, sameAs} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import {CREATE_USER_MUTATION, USER_COMPANY_DETAILS_FOR_ADD} from "../../../constants/user-graphql";
import { handleFormValidation, fleshErrorMessage } from "@/error/server-error";

export default {
  name: "AddUser",
  mixins: [validationMixin],
  data () {
    return {
      enabledModulesChoices: {},
      headquartersChoices: {},
      addUserForm: {
        firstName: null,
        lastName: null,
        email: null,
        plainPassword: null,
        passwordConfirm: null,
        enabledModules: null,
        headquarter: null,
      },
      errors: {
        addUserForm: {},
      },
    }
  },
  validations: {
    addUserForm: {
      firstName: {
        required,
        minLength: minLength(3)
      },
      lastName: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email
      },
      plainPassword: {
        required,
        minLength: minLength(6)
      },
      passwordConfirm: {
        sameAsPassword: sameAs('plainPassword')
      },
      headquarter: {
        required
      },
      enabledModules: {},
    }
  },
  methods: {
    onAddUserSubmit () {
      this.errors.addUserForm = {};
      this.$v.addUserForm.$touch()
      if (this.$v.addUserForm.$anyError) {
        return;
      }
      this.$apollo.mutate({
        mutation: CREATE_USER_MUTATION,
        variables: {
          'firstName': this.$v.addUserForm.firstName.$model,
          'lastName': this.$v.addUserForm.lastName.$model,
          'email': this.$v.addUserForm.email.$model,
          'password': this.$v.addUserForm.plainPassword.$model,
          'headquarterID': this.$v.addUserForm.headquarter.$model,
          'enabledModules': this.$v.addUserForm.enabledModules.$model,
        }
      }).then(() => {
        this.flashMessage.info({message: "Utilizatorul este creat cu succes!"});
        this.$router
            .push('/user/list')
            .catch(error => {
              error = {};
              fleshErrorMessage(error, this);
            });
      }).catch((error) => {
        let form = this.$v.addUserForm;
        this.errors.addUserForm = handleFormValidation(error, form);
        fleshErrorMessage(error, this);
      })
    },
    backToList() {
      this.hasHistory() ?
          this.$router.go(-1) :
          this.$router.push('/user/list')
      ;
    },
    hasHistory() {
      return window.history.length > 2
    },
    removeServerErrors(form) {
      this.errors[form] = {};
    }
  },
  apollo: {
    meUser: {
      query: USER_COMPANY_DETAILS_FOR_ADD,
      fetchPolicy: "no-cache",
      result(result) {
        this.enabledModulesChoices = result.data.meUser.company.enabledModuleChoices;
        let choices = [];
        choices.push({'value': null, 'text': 'Selecteaza punctul de lucru'});
        result.data.meUser.company.headquarters.forEach(function(item) {
          choices.push({'value': item._id, 'text': item.name});
        });
        this.headquartersChoices = choices;
        this.loaded = true;
      }
    }
  },
}
</script>